/* eslint-disable */
import { AddMessagingServiceConfigurationRequest, MessagingServiceConfigurationRequest } from "../contracts/MessagingServiceConfigurationRequest";
import { MessagingServiceConfigurationForm } from "@/domain/entities/MessagingServiceConfiguration";
import moment from "moment";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class AddMessagingServiceConfigurationApiRequest implements AddMessagingServiceConfigurationRequest {
    name: string;
    type: string;
    sttLastStatus: string;
    packetType: string;
    target: string;
    bookedFor: string;
    product: string;
    originCity: string;
    origincityExclude: string;
    posId: string;
    posIdExclude: string;
    clientId: string;
    clientIdExclude: string;
    schedulePeriodeStart: string;
    schedulePeriodeEnd: string;
    messageText: string;
    templateNameWaPremium: string;
    msgConfIntracity: boolean;
    msgConfIntercity: boolean;
    clientParentId: string;
    clientParentIdExclude: string;
    destinationCity: string;
    destinationCityExclude: string;

    constructor(
        name: string,
        type: string,
        sttLastStatus: string,
        packetType: string,
        target: string,
        bookedFor: string,
        product: string,
        originCity: string,
        origincityExclude: string,
        posId: string,
        posIdExclude: string,
        clientId: string,
        clientIdExclude: string,
        schedulePeriodeStart: string,
        schedulePeriodeEnd: string,
        messageText: string,
        templateNameWaPremium: string,
        msgConfIntracity: boolean,
        msgConfIntercity: boolean,   
        clientParentId: string,
        clientParentIdExclude: string,
        destinationCity: string,
        destinationCityExclude: string
    ) {
        this.name = name;
        this.type = type;
        this.sttLastStatus = sttLastStatus;
        this.packetType = packetType;
        this.target = target;
        this.bookedFor = bookedFor;
        this.product = product;
        this.originCity = originCity;
        this.origincityExclude = origincityExclude;
        this.posId = posId;
        this.posIdExclude = posIdExclude;
        this.clientId = clientId;
        this.clientIdExclude = clientIdExclude;
        this.schedulePeriodeStart = schedulePeriodeStart;
        this.schedulePeriodeEnd = schedulePeriodeEnd;
        this.messageText = messageText;
        this.templateNameWaPremium = templateNameWaPremium;
        this.msgConfIntracity = msgConfIntracity;
        this.msgConfIntercity = msgConfIntercity;
        this.clientParentId = clientParentId
        this.clientParentIdExclude = clientParentIdExclude
        this.destinationCity = destinationCity
        this.destinationCityExclude = destinationCityExclude
    }

    toJSON(): string {
        return JSON.stringify({
            name: this.name,
            type: this.type,
            stt_last_status: this.sttLastStatus,
            packet_type: this.packetType,
            target: this.target,
            booked_for: this.bookedFor,
            product: this.product,
            origin_city: this.originCity,
            origin_city_exclude: this.origincityExclude,
            pos_id: this.posId,
            pos_id_exclude: this.posIdExclude,
            client_id: this.clientId,
            client_id_exclude: this.clientIdExclude,
            schedule_periode_start: this.schedulePeriodeStart,
            schedule_periode_end: this.schedulePeriodeEnd,
            message_text: this.messageText,
            template_name_wa_premium: this.templateNameWaPremium,
            msg_conf_intracity: this.msgConfIntracity,
            msg_conf_intercity: this.msgConfIntercity,
            client_parent_id: this.clientParentId,
            client_parent_id_exclude: this.clientParentIdExclude,
            destination_city: this.destinationCity,
            destination_city_exclude: this.destinationCityExclude
        });
    }
}

export class EditMessagingServiceConfigurationApiRequest implements MessagingServiceConfigurationRequest {
    form: MessagingServiceConfigurationForm = new MessagingServiceConfigurationForm();
    constructor(
      data: MessagingServiceConfigurationForm
    ) {
        this.form = data
    }

    toJSON(): string {
        const clientId = this.form.clientBranches.map((item: any) => {
            return item.value
        })
        const clientParentId = this.form.clientParent.map((item: any) => {
            return item.value
        })
        const clientIdExclude = this.form.clientBranchesExclude.map((item: any) => {
            return item.value
        })
        const clientParentIdExclude = this.form.clientParentExclude.map((item: any) => {
            return item.value
        })
        return JSON.stringify({
            id: this.form.configId,
            name: this.form.messageTitle,
            type: this.form.messageType,
            stt_last_status: this.form.eventStatus,
            packet_type: this.form.packageType,
            target: this.form.target.join(","),
            booked_for: this.form.appliedTo.join(","),
            product: this.form.product.join(","),
            origin_city: this.form.originCities.map((item: any) => {
                return item.value
            }).join(","),
            origin_city_exclude: this.form.originCitiesExclude.map((item: any) => {
                return item.value
            }).join(","),
            pos_id: this.form.posPartners.map((item: any) => {
                return item.value
            }).join(","),
            pos_id_exclude: this.form.posPartnersExclude.map((item: any) => {
                return item.value
            }).join(","),
            client_id: [clientId.includes("All") ? "All" : clientId].join(),
            client_id_exclude:[...clientIdExclude].join(),
            schedule_periode_start: moment(this.form.startDate).format("YYYY-MM-DD"),
            schedule_periode_end: moment(this.form.endDate).format("YYYY-MM-DD"),
            message_text: this.form.messageDetail,
            msg_conf_intracity: this.form.msgConfIntracity,
            msg_conf_intercity: this.form.msgConfIntercity,
            client_parent_id: [...clientParentId].join(),
            client_parent_id_exclude: [...clientParentIdExclude].join(),
            destination_city: this.form.destinationCities.map((item: any) => {
                return item.value
            }).join(","),
            destination_city_exclude: this.form.destinationCitiesExclude.map((item: any) => {
                return item.value
            }).join(","),
            
        });
    }
}

export class RequestMessagingServiceConfigurationList {
    page = 1; 
    limit = 10; 
    status = ""; 
    messageType = ""; 
    packageType = ""; 
    eventStatus =  ""
    isTotalData = false;
    version = "1";
    noAttribute = true;


    constructor(fields?: Partial<RequestMessagingServiceConfigurationList>) {
        Object.assign(this, fields);
      }
    
      toQueryString(): string {
        return new QueryParamsEntities(this).queryString;
      }
}