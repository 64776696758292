
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { MessagingServiceConfigurationListData } from "@/domain/entities/MessagingServiceConfiguration";
import {
  checkRolePermission,
  formatDate,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { MessagingServiceConfigurationController } from "@/app/ui/controllers/MessagingServiceConfigurationController";
import { MESSAGE_CONFIG } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    DetailLayout,
    Title
  }
})
export default class MessagingServiceConfigurationDetail extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  get ableToEdit() {
    let isAble = false;
    switch (this.detailData.msgConfStatus) {
      case "active":
      case "waiting":
      case "nearly-expired":
        isAble = true;
        break;
      default:
        break;
    }
    return checkRolePermission(MESSAGE_CONFIG.EDIT) && isAble;
  }

  get id(): any {
    return this.$route.params.id;
  }

  // route navigatiion
  goBack() {
    this.$router.push("/admin/messaging-service-configuration");
  }
  goToEdit() {
    this.$router.push(`/admin/messaging-service-configuration/${this.id}/edit`);
  }

  fetchDetailData() {
    MessagingServiceConfigurationController.getMessageServiceDetail(this.id);
  }

  get detailData(): MessagingServiceConfigurationListData {
    return MessagingServiceConfigurationController.messageServiceDetail;
  }

  mappingRouteMsgConfig(item: any) {
    if (item.msgConfIntracity && item.msgConfIntercity) return 'INTRACITY,INTERCITY';
    if (item.msgConfIntracity)return 'INTRACITY';
    if (item.msgConfIntercity) return 'INTERCITY';
    else return "-";
  }

  onChipStatus(status: string) {
    let chip = "";
    switch (status) {
      case "waiting":
      case "pending":
        chip =
          "bg-gray-lp-200 text-black-lp-300 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "nearly-expired":
        chip =
          "bg-yellow-lp-200 text-yellow-lp-100 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "inactive":
      case "expired":
        chip =
          "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "active":
        chip =
          "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
        break;
      default:
        break;
    }
    return chip;
  }

  stringToArray(data: string) {
    const res = data.split(",");
    return res;
  }

  formatJsonToString(data: string) {
    const res = data.split("_").join(" ");
    return this.convertToUppercase(res);
  }

  convertToUppercase(value: string) {
    const tmp = value.toLowerCase();
    let res = "";
    const convertCapital: any = {
      wa: "WA",
      sms: "SMS",
      cod: "COD",
      pos: "POS",
      dfod: "DFOD"
    };
    Object.keys(convertCapital).forEach(e => {
      if (tmp.includes(e)) {
        res = tmp.replaceAll(e, convertCapital[e]);
      }
    });
    return res || value;
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  get periodeDate() {
    return `${formatDateWithoutTime(
      this.detailData.msgConfSchedulePeriodeStart,
      true
    )} - ${formatDateWithoutTime(
      this.detailData.msgConfSchedulePeriodeEnd,
      true
    )}`;
  }

  // error
  get isLoading() {
    return MessagingServiceConfigurationController.isLoading;
  }
  get errorCause() {
    return MessagingServiceConfigurationController.errorCause;
  }
}
